common-navigation-button.active>.button {
  background: $common-nav-btn-active-bg;
  color: white;
}

common-dropzone .overlay .text {
  background: #999;
  color: #333;
}

.dark-theme {
  common-dropzone .overlay .text {
    background: #666;
    color: #ddd;
  }
}