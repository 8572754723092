$white: #fff;
$gray-100: #f3f3f3;
$gray-200: #e4e4e4;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #888;
$gray-700: #444;
$gray-750: #383838;
$gray-800: #303030;
$gray-900: #222;
$black: #000;

$blue: #375a7f;
$lightblue: #1e8bff;
$indigo: #6610f2;
$hoki: #5e7a88;
$arapawa: #284d61;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #e74c3c;
$orange: #fd7e14;
$yellow: #f39c12;
$green: #00bc8c;
$teal: #20c997;
$cyan: #3498db;