.papers-scrollbar::-webkit-scrollbar,
.papers-scrollbar:hover::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.papers-scrollbar::-webkit-scrollbar-track {
  background-color: $scroll-track;
}

.papers-scrollbar::-webkit-scrollbar-thumb {
  background-color: $scroll-thumb;
  border-radius: 5px;
  outline: none;
  border: none;
}

.papers-scrollbar::-webkit-scrollbar-corner {
  background-color: $scroll-track;
}

.papers-scrollbar::-webkit-scrollbar-thumb:hover {
  opacity: 1;
}

.papers-button:hover {
  background: $button-hover-color;
}

.papers-label {
  color: $label-color;

  a {
    color: inherit;
  }
}

.papers-text-color-secondary {
  color: $text-color-secondary;
}

.papers-link-active {
  background: $dropdown-link-active-bg;
  color: $dropdown-link-active-color;
}

.papers-badge {
  font-size: 11px;
  font-weight: 400;
  margin-right: 5px;
  margin-bottom: 3px;
  padding: 5px;
  max-width: 200px;
  background-color: $badge-bg;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: text;
}

.papers-badge,
.papers-badge a {
  color: $body-color;
  text-decoration: none;
  user-select: text;
}

.papers-locate-button {
  position: relative;
  padding: 7px;
  width: 100%;
}

.papers-locate-button .icon {
  float: left;
  width: 40px;
  font-size: 22px;
}

.papers-locate-button .text {
  margin-left: 40px;
}

.papers-locate-button .text .big {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4;
}

.papers-locate-button .text .big,
.papers-locate-button .text .small {
  text-align: left;
  margin: 0px;
  padding: 0px;
}

.papers-file-button {
  position: relative;
  width: 100%;
  margin-top: 8px;
  padding: 9px 7px;
  font-size: 12px;
}

.papers-file-button--disabled {
  background-color: $gray-400 !important;
  border-color: $gray-400 !important;
  color: white;
}

.papers-file-button .icon {
  position: absolute;
  top: -7px;
  left: 5px;
}

.papers-file-button .text {
  margin-left: 0;
  font-weight: bold;
}

.papers-file-button .text--indent {
  margin-left: 30px;
}

.papers-border-bottom {
  border-bottom: 1px solid $table-border-color;
}

.papers-border-top {
  border-top: 1px solid $table-border-color;
}

.papers-border-left {
  border-left: 1px solid $table-border-color;
}

.papers-border-right {
  border-right: 1px solid $table-border-color;
}

.papers-border {
  border: 1px solid $table-border-color;
}
