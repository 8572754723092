html, body {
  margin: 0;
  height: 100%;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // Prevents scrollbars from appearing when dragging
  overflow: hidden;
}

select {
  appearance: none;
  background:
    linear-gradient(45deg, transparent 50%, rgb(150, 150, 150) 50%),
    linear-gradient(135deg, rgb(150, 150, 150) 50%, transparent 50%);
  background-position:
    calc(100% - 14px) 14px,
    calc(100% - 10px) 14px;
  background-size: 4px 4px;
  background-repeat: no-repeat;
}

em {
  font-style: normal;
  font-weight: bold;
}

input::placeholder,
input::-webkit-input-placeholder {
  color: #ccc !important;
}

input:focus::placeholder,
input:focus::-webkit-input-placeholder {
  color: transparent !important;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: none;
}

input.form-control[readonly] {
  background-color: unset;
  opacity: .5;
}

select.form-control[disabled] {
  background-color: unset;
  opacity: .5;
}

blockquote {
  color: $gray-500;
}

textarea {
  color: $body-color;
  background-color: $input-bg;
  border-color: $input-border-color;
}

.pointer {
  cursor: pointer !important;
}

a.disabled {
  pointer-events: none !important;
  cursor: not-allowed !important;
}
