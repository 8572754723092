.ngx-slider {
  .ngx-slider-bubble,
  .ngx-slider-bubble.ngx-slider-limit,
  .ngx-slider-model-value,
  .ngx-slider-bubble
  .ngx-slider-model-high,
  .ngx-slider-combined {
    color: $body-color !important;
  }
}
