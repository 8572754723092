/* Form control alerts */
.form-control.ng-valid[required],
.form-control.ng-valid.required {
  border: 1px solid #42A948; // TODO: Variable?
}

.form-control.ng-invalid,
.form-select.ng-invalid {
  border: 1px solid #a94442; // TODO: Variable?
}

.form-control.ng-pending,
.form-select.ng-pending {
  border: 1px solid rgb(255, 196, 0); // TODO: Variable?
}

.form-control.ng-warning {
  border: 1px solid rgb(220, 88, 42); // TODO: Variable?
}

/* Buttons */
.btn {
  font-size: inherit;
}

.btn-primary {
  border: none;
  color: white;
  background-color: #37738d; // TODO: Variable?

  &:disabled,
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    color: white;
    background-color: #37738d; // TODO: Variable?
  }

  &:disabled {
    background-color: #37738d; // TODO: Variable?
  }

  &:hover {
    background-color: $arapawa;
  }

  &:focus,
  &:active,
  &:active:focus {
    background-color: #203d4d; // TODO: Variable?
  }
}

.btn-secondary {
  color: $body-color;
  background-color: $input-bg;
  border-color: $input-border-color;
}

.btn-link {
  text-decoration: none;
}

/* Modal dialog */
.modal {
  z-index: 10001;
}

.modal-backdrop {
  z-index: 10000;
}

.modal-header .modal-title {
  font-size: 18px;
}

.modal-content {
  border: none;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  animation-duration: 0.3s;
}

.modal-body {
  font-size: 13px;
}

.modal-dialog-max-content {
  max-width: max-content;
}

.modal-dialog-max-content .modal-content {
  width: max-content;
}

/* Dropdown menu */
.dropdown-menu {
  border-radius: 5px;
  font-size: 12px;
  max-height: 550px;
  box-shadow: rgba(0, 0, 0, 0.4) 0 6px 20px;
  overflow-x: hidden;
  overflow-y: auto;

  // 414px is the landscape height of iPhone 6/7/8 Plus
  @media (max-height: 414px) {
    max-height: 280px;
  }
}

.dropdown-menu .dropdown-item {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
  margin-right: 5px;
}

.dropdown-toggle.no-caret:after {
  content: none;
}

.dropdown .dropdown-toggle::after {
  border: solid;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  margin-left: 7px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown .dropdown-item.checked::after {
  float: right;
  content: "✓";
  font-weight: 600;
}

/* Tooltip */
.dark-theme .tooltip .tooltip-inner {
  color: $body-color;
}

/* Popover */
.bs-popover-top .arrow::after {
  border-top-color: $popover-bg;
}

.bs-popover-bottom .arrow::after {
  border-bottom-color: $popover-bg;
}

/* Datepicker */
.ngb-dp-day .btn-light:hover {
  background-color: transparent !important;
}

.ngb-dp-today .btn-light {
  background-color: $body-bg !important;
}

.ngb-dp-header {
  background-color: $popover-bg !important;
}

.ngb-dp-week {
  background-color: $popover-bg !important;
}

/* Accordion */
.accordion-button {
  color: $label-color;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.13px;
  margin-bottom: 5px;
}

.dark-theme .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e4e4e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
