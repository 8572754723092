@import "./variables_colors";
@import "./variables_light";
@import "bootstrap/scss/bootstrap";
@import "./bootstrap";
@import "./html_default";
@import "./papers_classes";

// External modules
@import "./ng_cdk";
@import "./ng_split";
@import "./ngx_charts";
@import "./ngx_slider";
@import "./rcp_data_view";
@import "./rcp_token_field";

// Local modules
@import "./common";
@import "./importer";
@import "./library";
@import "./search";

.dialog>.inner {
  color: $body-color;
  background-color: $body-bg;
}