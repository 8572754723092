search-results-table,
library-results-table,
library-results-list {

  data-view-table .viewport,
  data-view-list .viewport {
    background-color: $table-bg;
  }

  data-view-list-row,
  data-view-table-row {
    background-color: $table-row-odd;
  }

  data-view-list-row.even,
  data-view-table-row.even {
    background-color: $table-row-even;
  }

  data-view-list-row.selected,
  data-view-table-row.selected {
    background-color: $dropdown-link-active-bg;
    color: $dropdown-link-active-color;
  }

  data-view-list-row.selected .cell a,
  data-view-table-row.selected .cell a {
    color: $dropdown-link-active-color;
  }

  data-view-list-row.selected .cell a:hover,
  data-view-table-row.selected .cell a:hover {
    color: darken($dropdown-link-active-color, 5%);
  }

  data-view-list-row.highlighted,
  data-view-table-row.highlighted {
    outline: 1.5px $dropdown-link-active-bg solid;
  }

  data-view-table-head {
    border-bottom: 1px solid $table-border-color;
    color: $body-color;
  }

  data-view-table-head .cell:hover {
    background-color: darken($body-bg, 5%);
  }

  data-view-table-head .cell .resize-handle {
    border-right: 1px solid $table-border-color;
  }
}

library-results-list {

  // Fixes overflow under sidepanel that is expected for table view but not for list view.
  .cdk-virtual-scroll-content-wrapper {
    max-width: 100%;
  }
}

search-results-list {
  data-view-list .viewport {
    background-color: $table-bg;
  }

  data-view-list-row {
    padding: 12px 16px;
    border-top: 1px solid $table-border-top;
  }

  // data-view-list-row {
  //   background-color: $table-row-odd;
  // }

  // data-view-list-row.even {
  //   background-color: $table-row-even;
  // }

  data-view-list-row.selected .title {
    color: $lightblue;
  }

  data-view-list-row.selected .cell a {
    color: $dropdown-link-active-color;
  }

  data-view-list-row.selected .cell a:hover {
    color: darken($dropdown-link-active-color, 5%);
  }

  data-view-list-row.highlighted {
    outline: 1.5px $dropdown-link-active-bg solid;
  }

  data-view-list-row .result>.metadata .authors {
    color: $results-author-color !important;
  }
}