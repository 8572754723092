importer-progress .nav {
  background-color: rgb(225, 225, 230);
}

importer-progress ul.list>li {
  background-color: rgb(225, 225, 230);
}

.dark-theme {
  importer-progress .nav {
    background-color: #383838;
  }

  importer-progress ul.list>li {
    background-color: #383838;
  }
}
