$yiq-contrasted-threshold: 175 !default;

$primary: $lightblue !default;
$secondary: $gray-700 !default;
$success: $green !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$scroll-track: $gray-200 !default;
$scroll-thumb: $gray-400 !default;
$badge-bg: $gray-300 !default;

// Body

$body-bg: #F0F4F5 !default;
$body-color: $dark !default;

// Links

$link-color: $primary !default;

// Tables

$table-row-odd: lighten($gray-100, 3) !default;
$table-row-even: $gray-100 !default;
$table-border-color: $gray-400 !default;
$table-bg: lighten($gray-100, 3) !default;
$table-border-top: #f0f4f5;

// Forms

$input-border-color: $gray-200 !default;
$input-border-color-hover: $gray-400 !default;

// Dropdowns

$dropdown-bg: $white !default;
$dropdown-border-color: $gray-200 !default;
$dropdown-divider-bg: $gray-200 !default;

// Common navigation button
$common-nav-btn-active-bg: $hoki;

// Modals

$modal-content-bg: $gray-100 !default;
$modal-content-border-color: $gray-200 !default;
$modal-header-border-color: $gray-200 !default;

// Progress bars

$progress-bg: $gray-400 !default;

// Popovers

$popover-bg: $white !default;
$popover-header-bg: $white !default;

// Inputs

$input-bg: $white !default;
$input-disabled-bg: $gray-200 !default;
$input-color: $gray-900 !default;
$form-select-indicator-color: $dark !default;

// App bar
$app-bar-blue: $primary !default;
$app-bar-green: $green !default;
$app-bar-purple: $purple !default;

// Search results author
$results-author-color: $arapawa;

// Labels

$label-color: #292F39;

// Buttons

$btn-close-color:  $gray-900;
$button-hover-color: rgba(75,125,150,.1);

// Text
$text-color-secondary: #284D61;