search-query-header .search-box {
  background-color: $input-bg;
  border-color: $input-border-color;
}

.search-navigation .customize-filters {
  border: 1px solid #37738d;
  color: $arapawa;
}

.landing-container {
  .radio-wrapper {
    input:checked~.checkmark {
      background-color: $arapawa;
    }

    input:checked~.text {
      color: #292f39;
    }
  }
}

.dark-theme {
  .search-navigation .customize-filters {
    border: 1px solid white;
    color: $body-color;
  }

  .landing-container {
    .search-wrapper .ng-fa-icon {
      color: white;
    }

    .radio-wrapper {
      .checkmark:after {
        background-color: $hoki !important;
      }

      input:checked~.text {
        color: white !important;
      }

      input:checked~.checkmark {
        background-color: white !important;
      }
    }
  }
}