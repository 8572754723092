rcp-token-field,
rcp-token-field-picker,
rcp-token-field-editable {
  .token:not(.error) {
    color: $body-color;
    background-color: darken($input-bg, 5);
  }

  .token.error {
    color: $body-color;
    background-color: #D0021B;
  }
}

rcp-token-field-editable-input::selection,
rcp-token-field-editable-input::-moz-selection {
  background: cornflowerblue;
}